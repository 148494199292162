import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bingsu_logo_no_circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img style={{
    width: `20rem`,
    margin: `0 auto`
  }} fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Logo
