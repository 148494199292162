import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Characters from "../components/characters"
import Logo from "../components/logo"
import Facebook from "../components/facebook"
import Instagram from "../components/instagram"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Logo  />
    <SEO title="Bingsu - Korean Dessert Cafe" />
     <Characters />
  </Layout>
)

export default IndexPage
