import React from "react";
import PropTypes from "prop-types";
import Image from "./image";
import Chocolate from "./chocolate";
import Coconut from "./coconut";
import Cookies from "./cookies";
import Apple from "./apple";
import Berry from "./berry";
import Nutella from "./nutella";
import "./image.scss";

const Characters = () => {
  return (
    <div>
      <div
        className="character-container"
      >
        <Image />
        <Coconut />
        <Chocolate />
        <Apple />
        <Berry />
        <Cookies />
        <Nutella />
      </div>
    </div>
  )
}

export default Characters;
